import { ConsentComponent, ShopifyConfig } from '@ifixit/consent-banner';
import { initializeChakraComponent } from 'Shared/chakra-initialize';
import { Link, LinkProps } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

function CarpenterConsentComponent({
   isTest,
   flagEnabled,
   privacyPolicyUrl = '/Info/Privacy',
   piwikSiteId,
   needsConsent,
   shopifyConfig,
}: {
   isTest: boolean;
   flagEnabled: boolean;
   privacyPolicyUrl?: string;
   piwikSiteId: string;
   needsConsent: boolean;
   shopifyConfig: ShopifyConfig;
}) {
   if (isTest && !flagEnabled) {
      return null;
   }

   const CustomLink = ({ children, ...linkProps }: PropsWithChildren<LinkProps>) => (
      <Link textDecoration={'underline'} {...linkProps}>
         {children}
      </Link>
   );

   return (
      <ConsentComponent
         CustomLink={CustomLink}
         privacyPolicyUrl={privacyPolicyUrl}
         needsConsent={needsConsent}
         piwikSiteId={piwikSiteId}
         shopifyConfig={shopifyConfig}
      />
   );
}

initializeChakraComponent('ConsentBanner', CarpenterConsentComponent);
